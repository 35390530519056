import React from "react";
import Artykul from "../components/artykul";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import obrazekArtykulu from "../images/baza-urzadzen-questy-tlo.jpg";
import Lightbox from "../components/lightBox";
import ResponsiveEmbed from "react-responsive-embed";
import { Link } from "gatsby";
import * as styles from "./index.module.css";


export const query = graphql`
  {
    zdjecie1: file(
      relativePath: { eq: "questy-kartoteki-produktow-serwisowanych.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 700, quality: 95, layout: CONSTRAINED)
      }
    }
    zdjecie2: file(relativePath: { eq: "atrybuty-baza-urzadzen-questy.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 95, layout: FULL_WIDTH)
      }
    }
  }
`;

const Bazaurzadzen = ({ data }) => {
  return (
    <Artykul
      title="Sprawnie zarządzaj bazą urządzeń"
      keywords={["baza urządzeń serwisowanych"]}
      articleImage={obrazekArtykulu}
      articleImageAlt="Sprawnie zarządzaj bazą urządzeń"
      metaTitle="Sprawnie zarządzaj bazą urządzeń"
      metaDescription="Uporządkowana historia napraw maszyn i urządzeń ✅ Mobilny dostęp do informacji o zleceniach • Obejrzyj video"
    >
      <p>
        Jeżeli Twoja firma wykonuje usługi serwisowe u klientów, cykliczne
        przeglądy maszyn i naprawy urządzeń, z&nbsp;pewnością zbiera dużo
        informacji na temat tego sprzętu. Aby kolejne zlecenia realizować jak
        najsprawniej, warto aby pracownik, który dane zadanie podejmuje, miał do
        tej historii dostęp. Przydatna jest tutaj{" "}
        <strong>
          baza urządzeń serwisowanych w systemie do zarządzania serwisem
        </strong>
        .
      </p>
      <br />
      <ResponsiveEmbed
        src="https://www.youtube.com/embed/BweIoQaKSNg"
        allowFullScreen
        ratio="16:9"
      />
      <br />
      <h2>Odejdź od papierowych wersji</h2>
      <p>
        Korzystając z oprogramowania do zarządzania zleceniami możesz w wygodny
        sposób - online - prowadzić całą historię wykonanych napraw.
        Elektroniczna baza urządzeń serwisowanych to{" "}
        <strong>
          duże ułatwienie pracy zespołu, który w każdej chwili może wyszukać
          potrzebne informacje
        </strong>
        . Pracownicy nie toną w papierach, nie ma też obawy, że jakieś dokumenty
        zaginą. Wszystko to znajduje się wewnątrz Twojego systemu do zarządzania
        serwisem. Ponadto dzięki aplikacji mobilnej, serwisanci nawet będąc u
        klienta mogą szybko sprawdzić szczegóły ich zlecenia.
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zarządzać bazą urządzeń w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać bazą urządzeń
            </Link>
          </div>
          </div>
    </section>
      <h2>Karta egzemplarza serwisowanego</h2>
      <p>
        Kluczem do uporządkowanej wiedzy o naprawianych maszynach i
        urządzeniach, jest skonfigurowanie i&nbsp;prowadzenie kartotek
        egzemplarzy serwisowanych. Każda karta produktu posiada podstawowe
        informacje, takie jak:
      </p>
      <p>
        {" "}
        <ul>
          <li>nazwę i rodzaj produktu,</li>
          <li>numer seryjny,</li>
          <li>właściciela (czyli nazwę firmy Twojego klienta),</li>
          <li>dokładną datę zakupu,</li>
          <li>
            oznaczenie czy sprzęt jest aktywny i czy został zakupiony od Ciebie.
          </li>
        </ul>
      </p>
      <p>
        To tylko bazowe informacje. Możesz je rozbudować o dodatkowe atrybuty -
        o tym w dalszej części artykułu.
      </p>
      <br />
      <Lightbox
        style={{
          maxWidth: 900,
          margin: "0 auto",
        }}
        images={[data.zdjecie1]}
        alts={["egzemplarz-serwisowany"]}
      />
      <br />
      <p>Po prawej stronie widać jeszcze trzy ważne sekcje:</p>
      <p>
        <ul>
          <li>
            <strong>Powiązane procesy</strong> - czyli lista wszystkich zleceń,
            które wykonywane były w związku z tym właśnie urządzeniem
            (przykładowo mogła to być instalacja, naprawa, przegląd lub
            reklamacja). Na tym podglądzie prezentowany jest numer zlecenia,
            jego typ i raza, firma oraz osoba kontrahenta, a także data
            przyjęcia zgłoszenia i odpowiedzialny za nie pracownik. Klikając w
            numer procesu otworzy się jego karta z całą historią działań.
          </li>
          <li>
            <strong>Zadania</strong> - czyli wszystkie zaplanowane przez
            pracowników czynności powiązane z danym urządzeniem (przykładowo -
            zamówienie części, naprawienie konkretnego elementu lub kontakt z
            klientem w celu doprecyzowania zgłoszenia).
          </li>
          <li>
            <strong>Zdarzenia</strong> - czyli wykonane zadania i notatki
            pracowników, zarejestrowane czynności i ustalenia.
          </li>
        </ul>
      </p>

      <h2>Rozszerz zbierane dane</h2>
      <br />
      <p>
        Dodatkową sekcją, o jaką można rozbudować bazę urządzeń serwisowanych są
        atrybuty. To pola, które mogą przybierać dowolną formę, zgodnie z Twoimi
        potrzebami i specyfiką serwisowanych maszyn. Możesz zamieścić tam na
        przykład:
      </p>
      <p>
        <ul>
          <li>dodatkowy opis za pomocą pola tekstowego,</li>
          <li>
            ilość występowania określonego komponentu, poprzez pole typu liczba,
          </li>
          <li>listę rozwijaną z np. nazwą producenta,</li>
          <li>wskazać datę produkcji,</li>
          <li>dołączyć plik ze specyfikacją techniczną urządzenia.</li>
        </ul>
      </p>
      <p>
        <strong>Znacznie ułatwia to wyszukiwanie urządzeń</strong>, zwłaszcza
        gdy przykładowo klient nie można znaleźć numeru seryjnego maszyny, którą
        chce naprawiać. Wówczas wpisując nazwę firmy kontrahenta i kilka
        atrybutów, które jest w stanie wskazać - system wyszuka produkty
        spełniające te kryteria.
      </p>

      <Lightbox
        style={{
          maxWidth: 200,
          margin: "0 auto",
        }}
        images={[data.zdjecie2]}
        alts={["atrybuty urządzeń"]}
      />

      <br />
      <h2>Oszczędzaj na czasie!</h2>
      <br />
      <p>
        Wprowadzenie do przedsiębiorstwa kultury pracy polegającej na bieżącym
        rejestrowaniu wykonanych zadań w&nbsp;systemie do zarządzania serwisem,
        niesie za sobą wiele korzyści:
      </p>
      <p>
        <ul>
          <li>
            <strong>oszczędza czas pracowników</strong>, dzięki prostemu
            wyszukiwaniu maszyn i danych z nimi związanych,
          </li>
          <li>
            <strong>
              usprawnia przepływ informacji wewnątrz przedsiębiorstwa
            </strong>
            , poprzez notatki i checklisty serwisantów,
          </li>
          <li>
            <strong>ogranicza ryzyko popełnienia błędu</strong> przez
            pracownika, dając mu mobilny dostęp do bazy urządzeń,
          </li>
          <li>
            <strong>obniża koszty</strong> realizacji zleceń.
          </li>
        </ul>
      </p>
      <section
      style={{
        backgroundColor: "rgb(244, 244, 244, 0.75)",
        color: "rgb(48, 48, 48)",
        marginTop: 30,
        marginBottom: 30
      }}
    >
      <div className="container container--text"
      >
        <h3 style={{
          paddingTop: 30,
          textAlign: "center",
        }}>Chcesz zarządzać bazą urządzeń w Twoim biznesie?</h3>
        <p style={{
          textAlign: "center",
        }}
          >Napisz do nas, aby zyskać dostęp do bezpłatnej 30-dniowej wersji systemu

</p>

      <div
            style={{
              textAlign: "center",
              margin: "20px",
              padding: "10px",
            }}
          >
            <Link className={styles.startNow} to="/kontakt/">
              Chcę wypróbować i sprawniej zarządzać bazą urządzeń
            </Link>
          </div>
          </div>
    </section>
      <p>
        Inwestycja w narzędzie do elektronicznego zarządzania zleceniami to krok
        w kierunku nowoczesnego, sprawnie działającego serwisu. Zachęcamy do
        zapoznania się z pozostałymi materiałami opisującymi inne, przydatne
        funkcje naszych systemów:
      </p>
      <p>
        <ul>
          <li>
            <Link to="/7-wyzwan-w-serwisie/">
              7 problemów Twojego serwisu – jak je rozwiązać
            </Link>
          </li>
          <li>
            <Link to="/przeglady-cykliczne/">
              Jak skutecznie zarządzać zleceniami cyklicznymi?
            </Link>
          </li>
        </ul>
      </p>
    </Artykul>
  );
};

export default Bazaurzadzen;
